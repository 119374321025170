<template>
  <div class="the-home">
    <div class="home">
      <div class="home-bg">
        <div class="top1">
          <!-- <div class="search">
            <img src="../assets/image/sousuo.png"/>
            <input
              type="text"
              v-model="shopName"
              placeholder="请输入关键词进行搜索"
              placeholder-class="place"
               @input="search"
            @focus="search"

            />
             <img class="clears" v-if="isclear" src="../assets/image/cleans.png" @click="clearAll"/>
          </div>
          <div class="news">
            <img src="../assets/image/xiaoxi.png"/>
          </div>-->
        </div>
        <div class="cswipper">
          <van-swipe :autoplay="3000">
            <van-swipe-item v-for="(image, index) in bannerLists" :key="index">
              <img :src="image.bannerFile" data-url="image.bannerUrl" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="cFunctional">
        <div class="cFunctional-item" @click="registered()">
          <img src="../assets/image/guahao.png" />
          <p>预约挂号</p>
        </div>
        <div class="cFunctional-item" @click="medical()">
          <img src="../assets/image/tijian.png" />
          <p>体检</p>
        </div>
        <div class="cFunctional-item" @click="enterprise()">
          <img src="../assets/image/jiyin.png" />
          <p>基因检测</p>
        </div>
        <div class="cFunctional-item" @click="accompanying()">
          <img src="../assets/image/peizhen.png" />
          <p>陪诊</p>
        </div>
      </div>
      <div class="cFunctional">
        <div class="cFunctional-item" @click="green()">
          <img src="../assets/image/tongdao.png" />
          <p>绿色通道</p>
        </div>
        <div class="cFunctional-item" @click="insuranceMall()">
          <img src="../assets/image/shangcheng.png" />
          <p>保险商城</p>
        </div>
        <div class="cFunctional-item" @click="insuranceClaims()">
          <img src="../assets/image/lipei.png" />
          <p>保险理赔</p>
        </div>

        <div class="cFunctional-item" @click="more()">
          <img src="../assets/image/gengduo.png" />
          <p>更多服务</p>
        </div>
      </div>
      <div class="welfare" @click="corporateWelfare()">
        <img src="../assets/image/fulizhuanqiu.png" />
      </div>
      <div class="recommend">
        <div class="recommend-title">
          <div class="title-left">
            <div class="line"></div>
            <span>保险推荐</span>
          </div>
          <div class="title-right" @click="all()">
            <div class="spn">更多</div>
            <img src="../assets/image/next.png" />
          </div>
        </div>
        <!--  @click="tl(item.bxShopCompanyId,item.bxShopId,item.standby1)" -->
        <div class="recommend-items" v-for="(item,index) in shopList" :key="index">
          <div
            class="recommend-item"
            @click="tl(item.bxShopCompanyId,item.bxShopId,item.standby1,item.bxShopName,item.standby2,item.bxShopUrl)"
            :style="{borderBottom:(index ==shopList.length-1?'0':'1px solid #f5f5f5')} "
          >
            <div class="rec-left">
              <img :src="item.bxShopImage" />
            </div>
            <div class="rec-right">
              <div class="rec-top">
                <div class="rec-det">
                  <p class="det-title">{{item.bxShopName}}</p>
                </div>
                <div class="rec-img">
                  <img src="../assets/image/tuijian.png" />
                </div>
              </div>
              <div class="rec-bottom">
                <div class="univalent">
                  <span>￥</span>
                  <span>{{item.bxShopPrice}}</span>
                  <span>起</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar route v-model="active">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <!-- <van-popup v-model="show" closeable round :style="{ height: 'auto' }">
      <img src="../assets/image/popup.png" alt class="png" />
      <p class="png-p">您的身份证信息已失效，请重新上传</p>
      <div class="png-btn">
        <button @click="noTip">不再提醒</button>
        <button @click="toUpload">立即上传</button>
      </div>
    </van-popup>-->

    <!-- <van-popup v-model="show1" closeable round :style="{ height: 'auto' }">
      <img src="../assets/image/popup.png" alt class="png" />
      <p class="png-p">您的身份证信息已失效，请重新上传</p>
      <div class="png-btn">
        <button @click="toUpload" class="show-btn">立即上传</button>
      </div>
    </van-popup>-->
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Tabbar, TabbarItem, Toast } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { Lazyload } from 'vant'
import { Rate } from 'vant'
import { Dialog } from 'vant'
import http from '../utils/config'
import con from '../utils/common'
import Axios from 'axios'
import { Popup } from 'vant'
import $ from 'jquery'

Vue.use(Popup)
Vue.use(Rate)
Vue.use(Lazyload)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Toast)
Vue.use(Swipe)
Vue.use(SwipeItem)
export default {
  name: 'home',
  components: {},
  data () {
    return {
      code: '',
      flag: 0,
      openId: '',
      accessToken: '',
      // openId
      active: 0,
      icon: {
        active: require('../assets/image/xuanzhong.png'),
        inactive: require('../assets/image/sy.png')
      },
      icon1: {
        active: require('../assets/image/wd.png'),
        inactive: require('../assets/image/meixuanzhong.png')
      },
      //  轮播
      images: [
        require('../assets/image/banner.png'),
        require('../assets/image/banner.png'),
        require('../assets/image/banner.png')

      ],
      // 评分器
      value: [{
        name: ''
      }, {
        name: ''
      }],
      shopList: [],
      bannerLists: [],
      isclear: false,
      shopName: '',
      show: false,
      show1: false,
    }
  },
  created () {

    this.getCode1()
    // this.showPopup()
    this.homeList()
    this.bannerList()
      .then(res => {
        this.bannerLists = res.data
      })
    this.$store.commit('corporateWelfare3', { value2: 0, value3: 0, istime: true })
    this.$store.commit('setInsuranceMall3', { value1: 0, value2: 0, value3: 0, istime: true })


  },

  mounted () {


  },
  methods: {
    ...mapActions('insuranceMall', ['insuranceshopList', 'toLink']),
    ...mapActions('home', ['checkCardOverdue', 'notReminder']),
    // showPopup () {
    //   if (localStorage.getItem('token') !== null) {
    //     var data = JSON.stringify({
    //       sign: 0
    //     })
    //     this.checkCardOverdue(data).then((res) => {
    //       if (res.code == 200) {

    //         if (res.falg == 0) {
    //           this.show = true
    //           localStorage.setItem('shenFenCard', 1)

    //         } else if (res.falg == 1) {
    //           this.show = false

    //         }
    //       }
    //     })
    //   }

    // },
    // noTip () {
    //   if (localStorage.getItem('token') !== null) {
    //     this.notReminder({
    //     }).then((res) => {
    //       if (res.code == 200) {
    //         this.show = false
    //         console.log(res.flag, 99999)
    //       }
    //     })
    //   }

    // },
    // toUpload () {
    //   this.$router.push({ path: '/card' })
    // },
    clearAll () {
      this.shopName = ''
      this.isclear = false
      this.newest()
    },
    search () {
      if (this.shopName != '') {
        this.isclear = true
      } else {
        this.isclear = false
      }
      this.newest()

    },
    newest () {
      this.modifyPasswrod({
        bxShopName: this.shopName,
        list: 999

      })
        .then(res => {
          this.shopList = res.rows
        })
    },
    tl (bxShopCompanyId, bxShopId, standby1, shopName, standby2, bxShopUrl) {
      if (standby2 == '0') {
        window.location.href = bxShopUrl

      } else {
        const listLink = {
          cid: bxShopCompanyId,
          pid: bxShopId,
          pcode: standby1,
          pname: shopName
        }
        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
          this.toLink(listLink).then(res => {
            if (res.code == 200) {
              window.location.href = res.msg
            } else {
              Toast(res.msg)
            }
          })
        } else {
          Dialog.confirm({
            title: '系统提示',
            message: '请登录后操作!',
            confirmButtonText: '确定',
            confirmButtonColor: "#5d6d8e",
            showCancelButton: false,
            className: 'tishi'
          }).then(() => {
            this.$router.push({
              name: 'Login'
            })

          })
        }

      }



    },
    // 查看更多跳转保险商城列表
    all () {
      this.$router.push({ path: '/home/insuranceMall' })
    },
    ...mapActions('home', ['modifyPasswrod', 'bannerList']),
    insuranceMall () {

      if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      } else {
        this.$router.push({ path: '/home/insuranceMall' })
      }


    },
    corporateWelfare () {//企业福利
      if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      } else {
        this.$router.push({ path: '/home/corporateWelfare' })
      }
      // this.$router.push({ path: '/home/corporateWelfare' })
      // Dialog.alert({
      //   confirmButtonText: '确定',
      //   confirmButtonColor: '#5d6d8e',
      //   showCancelButton: false,
      //   className: 'tishi',
      //   title: '系统提示',
      //   message: '此功能尚未开放!',
      // })
    },
    insuranceClaims () {//保险理赔
      /*if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      } else {*/
      window.location.href = con.bxclaimsUrl + localStorage.getItem('token')
      /*}*/



      //this.$router.push({ path: '/home/insuranceClaims' });
      /*Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: '#5d6d8e',
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      });*/
    },
    enterprise () {
      Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: '#5d6d8e',
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      })
    },
    registered () {
      Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: '#5d6d8e',
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      })
    },
    medical () {
      Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: '#5d6d8e',
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      })
    },
    accompanying () {
      Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: '#5d6d8e',
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      })
    },
    green () {
      if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      } else {
        this.$router.push({ path: '/home/greenPassage' })
      }
    // Dialog.alert({
    //     confirmButtonText: '确定',
    //     confirmButtonColor: '#5d6d8e',
    //     showCancelButton: false,
    //     className: 'tishi',
    //     title: '系统提示',
    //     message: '此功能尚未开放!',
    //   })
    },
    more () {
      Dialog.alert({
        confirmButtonText: '确定',
        confirmButtonColor: '#5d6d8e',
        showCancelButton: false,
        className: 'tishi',
        title: '系统提示',
        message: '此功能尚未开放!',
      })
    },
    homeList () {//首页商城列表
      this.modifyPasswrod({ list: 999 })
        .then(res => {
          this.shopList = res.rows
        })
    },
    // 授权登录
    getCode1 () { // 非静默授权，第一次有弹框

      const self = this
      const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
// this.getUrlParam('code')
      const local = window.location.href
      if (code == null || code == '' || code == undefined) {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + con.wxAppid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
        return                  //wx9961fc570b4dd673      wx7e8e9be5655b3c60
      } else {
        self.code = code
        var api1 = http + 'system/user/selectIwyCode'
        Axios({
          method: 'post',
          url: api1,
          params: {
            code: self.code
          }
        }).then(res => {

          if (res.data.code == 200) {
            localStorage.setItem('flag', res.data.flag)
            if (res.data.flag == 1) {
              localStorage.setItem('openId', res.data.standby2)
              localStorage.setItem('accessToken', res.data.accessToken)
              localStorage.setItem('token', '')
            } else if (res.data.flag == 2) {
              localStorage.setItem('token', res.data.token)
              localStorage.setItem('accessToken', res.data.accessToken)
            } else {
              localStorage.setItem('token', res.data.token)
              localStorage.setItem('accessToken', res.data.accessToken)
              Toast(res.data.msg)
            }

          } else {
            //Toast(res.data.msg)

          }
        })


      }
    },
    getUrlParam: function (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
  },
  computed: {

  },
};
</script>
<style scoped>
.png{
  	width: 2.74rem;
	height: 2.44rem;
    margin: 0.91rem 1.44rem 0.37rem;
}
.png-p {
  text-align: center;
  font-size: 0.3rem;
	/* font-weight: normal;
	font-stretch: normal; */
	line-height: 0.36rem;
	letter-spacing: 0rem;
	color: #666666;
}
.png-btn{
  margin-top: 0.66rem;
  margin-bottom: 0.82rem;
  display: flex;
  justify-content: center;
}
.png-btn button{
 border:solid 1px #c5c4c4;
  width: 2rem;
	height: 0.64rem;
	border-radius: 0.32rem;
  font-size: 0.3rem;
	/* font-weight: normal;
	font-stretch: normal; */
	line-height: 0.36rem;
	letter-spacing: 0rem;
}
.png-btn button:nth-child(1){
  	color: #949494;
  margin-right: 0.35rem;
  background-color: #ffffff;
}
.png-btn button:nth-child(2){
    border: 0 !important;
  color: #ffffff;
  background-color: #47a3ff;

}
.show-btn{
margin-right: 0 !important;
  color: #ffffff !important;
  border: 0 !important;
  	width: 3.06rem !important;
	background-color: #47a3ff  !important;
}
  .univalent {
    margin-top: 0.08rem;
    display: flex;
    height: auto;
    align-items:baseline;
  }

  .univalent span:nth-child(1) {
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    /* margin-top: 0.06rem; */
    font-family: PingFang-SC-Bold;
  }

  .univalent span:nth-child(2) {
    font-size: 0.36rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    font-family: PingFang-SC-Bold;
  }

  .univalent span:nth-child(3) {
    font-size: 0.24rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    /* margin-top: 0.06rem; */
    margin-left: 0.02rem;
    font-family: PingFang-SC-Bold;
  }

  .rec-bottom {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-top:0.3rem
  }

  .evaluate {
    display: flex;
    align-items: center;
  }

  .evaluate img {
    width: 0.27rem;
    display: block;
  }

  .evaluate span {
    font-size: 0.22rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #999999;
    display: block;
  }

  .rec-right, .rec-det {
    width: 100%
  }

  .rec-img img {
    width: 0.66rem;
    display: block;
    flex-shrink: 0;

  }

  .insure-Info, .rec-top {
    display: flex;
    justify-content: space-between;
  }

  .Info-item p:nth-child(1) {
    font-size: 0.22rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #999999;
    margin: 0.08rem 0;
    text-align: center;

  }

  .Info-item p:nth-child(2) {
    font-size: 0.24rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    text-align: center;

  }

  .det-title {
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    margin-top:0.2rem
  }

  .home-bg {
    height: auto;
    display: block;
    background: url('../assets/image/indexbg.png') no-repeat;
    background-size: 100% 100%;
  }

  .rec-left {
    width: 2.2rem;
    height: 1.6rem;
    border-radius: 0.08rem;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 0.23rem
  }

  .rec-left img {
    height: 100%;
    width: 100%;
    display: block;
  }

  .recommend-item {
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.4rem;
    display: flex;
    margin-bottom: 0.4rem

  }

  .recommend {

    width: 90%;
    margin: 0 auto;
    margin-top: 0.4rem;
  }

  .recommend-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.44rem;
    width: 100%;
    margin-bottom: 0.42rem
  }

  .title-left, .title-right {
    height: 100%;
    display: flex;
      height: 0.44rem;
    align-items: center;
  }

  .title-left span {
    /* font-family: PingFang-SC-Bold; */
    font-weight: 700;
    font-size: 0.32rem;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #000000;
    display: block;
  }

  .title-right .spn {
    font-size: 0.56rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #999999;
    display: block;
    transform: scale(0.5);
    margin-right: -0.3rem;
    -webkit-transform: scale(0.5);
  }

  .title-right img {
    height: 0.2rem;
    display: block;
    margin-left: 0.11rem;


  }

  .line {
    width: 0.06rem;
    height: 0.3rem;
    background-color: #47a3ff;
    border-radius: 0.03rem;
    transform: rotate(90);
    -ms-transform: rotate(90); /* IE 9 */
    -moz-transform: rotate(90); /* Firefox */
    -webkit-transform: rotate(90); /* Safari 和 Chrome */
    -o-transform: rotate(90);
    display: block;
    margin-right: 0.13rem;
  }

  .welfare {
    width: 84%;
    margin: 0 auto;
    margin-top: 0.32rem

  }

  .welfare img {
    width: 100%;
    margin-top: 0.5rem;
  }

  .cFunctional-item {
    width: 1.3rem;
    height: 100%;
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    text-align: center;
  }

  .cFunctional-item img {
    width: 1.2rem;
    display: block;
    margin: 0 auto;
    /* margin-bottom: 0.1rem */
  }

  .cFunctional {
    width: 84%;
    height: 1.47rem;
    margin: 0 auto;
    /* background: #f00; */
    margin-top: 0.52rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .cswipper {
    height: 3.2rem;
    background-color: #ffffff;
    box-shadow: 0.06rem 0.08rem 0.1rem 0rem rgba(87, 147, 239, 0.29);
    border-radius: 0.2rem;
    width: 90%;
    margin: 0 auto;
  }

  /* swipper 1*/
  .the-home {
    height: 100%;
    overflow-y: hidden;
    min-height:13rem;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .home {
    margin-bottom: 1rem;
        height: 100%;
    overflow-y: auto;
  }

  /* top */
  .top1 {
    height: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    overflow: hidden;
  }

  .search {
    display: flex;
    align-items: center;
    height: 0.7rem;
    background: rgba(255, 255, 255, 0.45);
    border-radius: 0.35rem;
    width: 100%;
    margin-right: 0.22rem

  }

  .search img {
    width: 0.28rem;
    height:0.27rem;
    margin-left: 0.25rem;
    margin-right: 0.12rem;
    flex-shrink: 0;
    display:block
  }

  .search input {
    border: 0;
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #ffffff;
    background: rgba(255, 255, 255, 0);
    width: 100%;


  }

  .news img, .news {
    width: 0.42rem;
    height: 0.46rem;
    display: block;
    flex-shrink: 0;
  }

  /* 轮播 */
  .van-swipe-item, .van-swipe, .van-swipe-item img {
    border-radius: 0.2rem;
  }
.clears{
    width:0.28rem;
    height:0.28rem;
    flex-shrink: 0;
    margin-right:0.3rem !important
  }
</style>
