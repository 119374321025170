import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/home.vue'),
		meta: {
			title: '首页'
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: () =>
			import('../views/register/register.vue'),
		meta: {
			title: '注册'
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () =>
			import('../views/login/login.vue'),
		meta: {
			title: '登录'
		},
	},
	{
		path: '/findPwd',
		name: 'FindPwd',
		component: () =>
			import('../views/findPwd/findPwd.vue'),
		meta: {
			title: '找回密码'
		},
	},
	{
		path: '/mine',
		name: 'Mine',
		component: () =>
			import('../views/mine/mine.vue'),
		meta: {
			title: '我的'
		},
	},
	// 购物车
	{
		path: '/shopCarList',
		name: 'shopCarList',
		component: () =>
			import('../views/shopping/shopCar.vue'),
		meta: {
			title: '购物车'
		},

	},
	// 我的订单
	{
		path: '/myOrder',
		name: 'myOrder',
		component: () =>
			import('../views/order/myOrder.vue'),
		meta: {
			title: '我的订单'
		},
	},
	// 首页保险商城
	{
		path: '/home/insuranceMall',
		name: 'insuranceMall',
		component: () =>
			import('../views/home/InsuranceMall.vue'),
		meta: {
			title: '保险商城'
		},

	},
	{
		path: '/home/greenPassage',
		name: 'greenPassage',
		component: () =>
			import('../views/home/greenPassage.vue'),
		meta: {
			title: '绿色通道'
		},

	},
	//绿色通道详情
	{
		path: '/greenDetail',
		name: 'greenDetail',
		component: () =>
			import('../views/greenDetail/greenDetail.vue'),
		meta: {
			title: '绿色通道详情'
		},
	},
	// 首页企业福利
	{
		path: '/home/corporateWelfare',
		name: 'corporateWelfare',
		component: () =>
			import('../views/home/CorporateWelfare.vue'),
		meta: {
			title: '首页企业福利'
		},
	},
	// 企业福利详情
	{
		path: '/corwelDetail',
		name: 'corwelDetail',
		component: () =>
			import('../views/corporate-welfare/corwelDetail.vue'),
		meta: {
			title: '企业福利详情'
		},
	},
	// 理赔类型
	{
		path: '/claimType',
		name: 'claimType',
		component: () =>
			import('../views/claimType/claimType.vue'),
	},
	// 首页保险理赔
	{
		path: '/home/insuranceClaims',
		name: 'insuranceClaims',
		component: () =>
			import('../views/home/insuranceClaims.vue'),
	},
	// 收获地址
	{
		path: '/addressList',
		name: 'addressList',
		component: () =>
			import('../views/addressList/addressList.vue'),
	},
	//新增地址
	{
		path: '/newAddress',
		name: 'newAddress',
		component: () =>
			import('../views/newAddress/newAddress.vue'),

	},
	//编辑地址
	{
		path: '/editAddress',
		name: 'editAddress',
		component: () =>
			import('../views/editAddress/editAddress.vue'),
	},
	// 保险理赔详情页
	// {
	//     path: '/home/insuranceClaims/insuranceDetail',
	//     name: 'insuranceClaims',
	//     component: () =>
	//         import ('../views/home/insuranceDetail.vue'),
	// },
	//身份认证
	{
		path: '/identity',
		name: 'identity',
		component: () =>
			import('../views/identity/identity.vue'),

	},
	{
		path: '/warranty',
		name: 'Warranty',
		component: () =>
			import('../views/warranty/warranty.vue'),
		meta: {
			keepAlive: false,
		}
	},
	{
		path: '/warranty2',
		name: 'Warranty2',
		component: () =>
			import('../views/warranty2/warranty.vue'),
		meta: {
			keepAlive: false,
		}
	},
	{
		path: '/warranty1',
		name: 'Warranty1',
		component: () =>
			import('../views/warranty1/warranty.vue'),
	},
	{
		path: '/wardetail',
		name: 'Wardetail',
		component: () =>
			import('../views/wardetail/wardetail.vue'),

	},
	{
		path: '/czrefund',
		name: 'Czrefund',
		component: () =>
			import('../views/czrefund/czrefund.vue'),

	},
	{
		path: '/application',
		name: 'Application',
		component: () =>
			import('../views/claim-application/application.vue'),
	},
	{
		path: '/cregister',
		name: 'Cregister',
		component: () =>
			import('../views/cregister/cregister.vue'),
		meta: {
			title: '注册'
		},
	},
	{
		path: '/newregister',
		name: 'Newregister',
		component: () =>
			import('../views/newregister/cregister.vue'),
		meta: {
			title: '注册'
		},
	},

	{
		path: '/card',
		name: 'card',
		component: () =>
			import('../views/card/card.vue'),
		meta: {
			title: '身份认证'
		},
	},
	{
		path: '/agreement',
		name: 'Agreement',
		component: () =>
			import('../views/agreement/agreement.vue'),
		meta: {
			title: '协议'
		},
	},
	{
		path: '/antiFraud',
		name: 'antiFraud',
		component: () =>
			import('../views/antiFraud/antiFraud.vue'),
		meta: {
			title: '反欺诈协议'
		},
	},
	{
		path: '/setup',
		name: 'Setup',
		component: () =>
			import('../views/setUp/setup.vue'),
		meta: {
			title: '设置'
		},
	},
	{
		path: '/orderSure',
		name: 'orderSure',
		component: () =>
			import('../views/orderSure/orderSure.vue'),
		meta: {
			title: '立即支付'
		},
	},
	{
		path: '/addressList',
		name: 'addressList',
		component: () =>
			import('../views/addressList/addressList.vue'),
		meta: {
			title: '地址列表'
		},
	},
	{
		path: '/editAddress',
		name: 'editAddress',
		component: () =>
			import('../views/editAddress/editAddress.vue'),
		meta: {
			title: '编辑地址'
		},
	},
	//支付成功
	{
		path: '/paySuccess',
		name: 'paySuccess',
		component: () =>
			import('../views/paySuccess/paySuccess.vue'),
		meta: {
			title: '支付成功'
		},
	},
	//订单详情
	{
		path: '/orderDetail',
		name: 'orderDetail',
		component: () =>
			import('../views/orderDetail/orderDetail.vue'),
		meta: {
			title: '订单详情'
		},
	},
	// 申请理赔
	{
		path: '/settlement',
		name: 'settlement',
		component: () =>
			import('../views/settlement/settlement.vue'),
		meta: {
			title: '申请理赔'
		},
	},
	//安阳申请理赔
	{
		path: '/aysettlement',
		name: 'aysettlement',
		component: () =>
			import('../views/aysettlement/settlement.vue'),
		meta: {
			title: '安阳申请理赔'
		},
	},
	//上传资料
	{
		path: '/uplaodata',
		name: 'uplaodata',
		component: () =>
			import('../views/uplaodata/uplaodata.vue'),
		meta: {
			title: '上传资料'
		},
	},
	//安阳上传资料
	{
		path: '/ayuplaodata',
		name: 'ayuplaodata',
		component: () =>
			import('../views/ayuplaodata/uplaodata.vue'),
		meta: {
			title: '安阳上传资料'
		},
	},
	//确认理赔信息
	{
		path: '/infoSure',
		name: 'infoSure',
		component: () =>
			import('../views/infoSure/infoSure.vue'),
		meta: {
			title: '确认理赔信息'
		},
	},
	//安阳确认理赔信息
	{
		path: '/ayinfoSure',
		name: 'ayinfoSure',
		component: () =>
			import('../views/ayinfoSure/infoSure.vue'),
		meta: {
			title: '安阳确认理赔信息'
		},
	},
	//提示
	{
		path: '/prompt',
		name: 'prompt',
		component: () =>
			import('../views/prompt/prompt.vue'),
		meta: {
			title: '提示'
		},
	},
	//理赔列表
	{
		path: '/claimList',
		name: 'claimList',
		component: () =>
			import('../views/claimList/claimList.vue'),
		meta: {
			title: '理赔列表'
		},
	},
	//安阳理赔列表
	{
		path: '/ayclaimList',
		name: 'ayclaimList',
		component: () =>
			import('../views/ayclaimList/claimList.vue'),
		meta: {
			title: '安阳理赔列表'
		},
	},
	//理赔详情
	{
		path: '/claimDetail',
		name: 'claimDetail',
		component: () =>
			import('../views/claimDetail/claimDetail.vue'),
		meta: {
			title: '理赔详情'
		},
	},
	//安阳理赔详情
	{
		path: '/ayclaimDetail',
		name: 'ayclaimDetail',
		component: () =>
			import('../views/ayclaimDetail/claimDetail.vue'),
		meta: {
			title: '安阳理赔详情'
		},
	},
	// 理赔修改
	{
		path: '/editClaim',
		name: 'editClaim',
		component: () =>
			import('../views/editClaim/editClaim.vue'),
		meta: {
			title: '理赔修改'
		},
	},
	// 安阳理赔修改
	{
		path: '/ayeditClaim',
		name: 'ayeditClaim',
		component: () =>
			import('../views/ayeditClaim/editClaim.vue'),
		meta: {
			title: '安阳理赔修改'
		},
	},
	//文件修改
	{
		path: '/editFlies',
		name: 'editFlies',
		component: () =>
			import('../views/editFlies/editFlies.vue'),
		meta: {
			title: '理赔图片修改'
		},
	},
	//安阳文件修改
	{
		path: '/ayeditFlies',
		name: 'ayeditFlies',
		component: () =>
			import('../views/ayeditFlies/editFlies.vue'),
		meta: {
			title: '安阳理赔图片修改'
		},
	},
	///补传资料
	{
		path: '/supplement',
		name: 'supplement',
		component: () =>
			import('../views/supplementImg/editFlies.vue'),
		meta: {
			title: '理赔图片补传'
		},
	},
	///安阳补传资料
	{
		path: '/aysupplement',
		name: 'aysupplement',
		component: () =>
			import('../views/aysupplementImg/editFlies.vue'),
		meta: {
			title: '安阳理赔图片补传'
		},
	},
	///查看pdf
	{
		path: '/openPdf',
		name: 'openPdf',
		component: () =>
			import('../views/openPdf/openPdf.vue'),
		meta: {
			title: '理赔图片修改'
		},
	},
	// 上门服务
	{
		path: '/doorService',
		name: 'doorService',
		component: () =>
			import('../views/doorService/doorService.vue'),
		meta: {
			title: '居家上门康复服务'
		},
	},
	// 出院陪护到家
	{
		path: '/accompany',
		name: 'accompany',
		component: () =>
			import('../views/accompany/accompany.vue'),
		meta: {
			title: '出院陪护到家'
		},
	},
	// 上门康复评估
	{
		path: '/estimate',
		name: 'estimate',
		component: () =>
			import('../views/estimate/estimate.vue'),
		meta: {
			title: '上门康复评估'
		},
	},
	//服务信息
	{
		path: '/message',
		name: 'message',
		component: () =>
			import('../views/message/message.vue'),
		meta: {
			title: '权益详情'
		},
	},
	// 权益详情
	{
		path: '/equityDatail',
		name: 'equityDatail',
		component: () =>
			import('../views/equityDatail/equityDatail.vue'),
		meta: {
			title: '权益详情'
		},
	},
	// 使用记录
	{
		path: '/useList',
		name: 'useList',
		component: () =>
			import('../views/useList/useList.vue'),
		meta: {
			title: '权益详情'
		},
	},
	// 权益中心
	{
		path: '/equityCenter',
		name: 'equityCenter',
		component: () =>
			import('../views/equityCenter/equityCenter.vue'),
		meta: {
			title: '权益中心'
		},
	},
	// 预约复诊
	{
		path: '/followUpAppointment',
		name: 'followUpAppointment',
		component: () =>
			import('../views/followUpAppointment/followUpAppointment.vue'),
		meta: {
			title: '预约复诊'
		},
	},
	// 历史卡券

	{
		path: '/historyCard',
		name: 'historyCard',
		component: () =>
			import('../views/historyCard/historyCard.vue'),
		meta: {
			title: '历史卡券'
		},
	},


]

const router = new VueRouter({
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	if (to.meta.needLoginIn) {

	} else {
		next()
	}

})
export default router
