import { get, post, post1,post2 } from '../../utils/request';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {

    // 多图片上传
    async uploadFlies({ commit }, payload) {
      var  warranty='/shop/claims/upload'
      const res = await post(warranty,payload);
      return res
    },
    // subApply 上传申请复诊预约挂号
    async subApply({ commit }, payload) {
      var  warranty='/yh/api/usageEquity'
      const res = await post2(warranty,JSON.stringify(payload));
      return res
    },


  },

};
