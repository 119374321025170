import { get, post, post1,post2 } from '../../utils/request';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    //获取验证码
    async serchDictionary({ commit }, payload) {
      const editDefaultAddress = 'system/dict/data/type/' + payload.dictType;
      const res = await get(editDefaultAddress,{});
      return res;
    },



  },

};
