<template>
  <div id="app">
   <!-- <router-view v-if='isRouterAlive'></router-view> -->
<keep-alive>
<router-view v-if="$route.meta.keepAlive"></router-view>
</keep-alive>
<router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },

  },
};
</script>

<style>
 .ren .van-uploader__upload{
    width: 3.18rem !important;
    height: 2.36rem !important;
    background: url('../src/assets/img/ren.png') no-repeat !important;
    background-size: contain !important;
  }
  .ren .van-uploader__upload-icon{
    display: none;
  }
  .ren .van-uploader__preview-image{
       width: 3.18rem !important;
    height: 2.36rem !important;
  }
  .guo .van-uploader__upload{
    width: 3.18rem !important;
    height:2.36rem !important;
    background: url('../src/assets/img/guo.png') no-repeat !important;
    background-size: contain !important;
  }
  .guo .van-uploader__upload-icon{
    display: none;
  }
  .guo .van-uploader__preview-image{
       width: 3.18rem !important;
    height: 2.36rem !important;
  }
.van-tabbar-item__icon img{
  height:0.50rem
}
.van-tabbar-item{
  font-size:0.24rem
}
.van-swipe{
  width:100%;
  height: 100%;
}
.van-swipe-item img{
  width:100%;
  height:100%;
  display: block;
}
.van-tabbar{
  	box-shadow: 0.01rem 0.06rem 0.17rem 0.01rem
		rgba(92, 86, 86, 0.23);
}
.warranty-item .van-tabs--line .van-tabs__wrap{
    height:1rem;
    border-bottom:1px solid  #d9e2e9;
}
.warranty-item .van-tab{
  color: #999999;
  font-size: 0.3rem;
  font-family: PingFang-SC-Medium;
}
.warranty-item .van-tab--active{
   color:#0d84fe;
   font-family: PingFang-SC-Bold;
}
.warranty-item .van-tabs__line {
    background-color: #0d84fe;
}
.sure .van-dialog{
  font-size: 0.3rem;
}
.sure .van-dialog__message{
  font-size:0.3rem
}
.sure .van-button{
  font-size:0.3rem
}
/*.van-button__text{*/
/*   color:#47a4ff;*/
/*}*/
.van-dialog__content{
  min-height:0.8rem
}
.bottom .van-button__text{
   color:#fff!important
}
.van-stepper__minus::before, .van-stepper__plus::before{
  height:0.02rem
}
.van-stepper__minus::after, .van-stepper__plus::after{
  width:0.02rem
}
.shopshi .van-checkbox__icon .van-icon{
    background: #eee;
  }
 .settlement .van-uploader{
    margin-left:0.2rem
}
.addressList-container .van-checkbox__label{
font-size: 0.30rem;
/* padding-top:0.05rem */
height:0.4rem;
line-height: 0.4rem;
}
.addressList-container .van-checkbox{
 height:0.4rem;
}
.statusnow .van-step--vertical:not(:last-child)::after{
      border-bottom-width: 0px;
}
.statusnow .van-step__circle{
background: rgba(255,255,255,0);
border: solid 1px #aec1d1;
}
 .flexList .van-switch{
    background-color: #dcdee0;
}
 .flexList .van-switch--on {
    background-color: #1989fa;
}
.editAddress-text .van-switch{
    background-color: #dcdee0;
}
.editAddress-text .van-switch--on {
    background-color: #1989fa;
}
.operation .van-button--normal{
      padding: 0 12px;
}
.settlement .van-step--vertical{
  padding-bottom:0
}
.van-icon-search::before{
  z-index:90!important
}
.thepopup{
  /* background: rgba(0,0,0,0); */
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ed .van-popup--center{
background: rgba(0,0,0,0);
}
.zhezhao{
  background-color:rgba(0,0,0,0)!important;
}
.equityCenter .van-popup--center.van-popup--round{
  border-radius: 0.1rem !important;
}
.equityCenter .van-cell{
  padding:0.32rem 0.32rem;
  padding-left:0
}
.uploadImg .van-uploader__upload{
  border-radius: 0.08rem;
    width:1.4rem;
  height:1.4rem

}
.uploadImg .van-uploader__preview {
border-radius: 0.08rem;
overflow: hidden;
}
.uploadImg .van-uploader__upload-icon{
   font-size:0.56rem
}
.uploadImg .van-uploader__preview-image{
  width:1.4rem;
  height:1.4rem
}
.equityCenter .van-cell::after{
  right:0;
  left:0
}
/* 更改input */
    .seedoorTime input{

height: 0.5rem !important;
line-height: 0.5rem  !important;
margin-top:0.05rem
    }

</style>
