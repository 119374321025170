import Vue from 'vue'
import Vant, { Lazyload } from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import { toCopy } from "./utils/tool"
// import  VueTouch from 'vue-touch'

// Vue.use(VueTouch,{name:'v-touch'})
Vue.use(Lazyload)
Vue.use(Vant)
Vue.config.productionTip = false
// Vue.use(Lazyload, {
//     lazyComponent: true,
// });
// VueTouch.config.swipe = {
//   threshold:5 //设置左右滑动的距离
// }
// 清空缓存
Vue.prototype.changeData = function () {//changeData是函数名
  var DetailList1 = {
    bxClaimsOrderNo: '',//保单号
    bxClaimsUserId: '',//用户ID
    bxClaimsShopId: '',//保险产品ID
    bxClaimsShopName: '',//保险产品名称
    bxClaimsCompanyId: '',//保险公司Id
    bxClaimsCompanyName: '',//保险公司名称

    bxClaimsInformantName: '',//报案人姓名
    bxClaimsInformantPhone: '',//报案人手机号
    bxClaimsInformantCode: '',//手机号验证码
    bxClaimsInsurantName: '',//被保人姓名
    bxClaimsInsurantCerType: '',//被保人证件类型
    bxClaimsInsurantCerNo: '',//被保人证件号
    bxClaimsInsurantGender: '',//被保人性别(1男 2女)

    bxClaimsDangerCause: '',//出险原因
    bxClaimsTotalPrice: '',//合计理赔金额
    bxClaimsInsuranceTime: '',//出险时间
    //出险地区
    province: '',//省
    city: '',//市
    area: '',//区
    bxClaimsDetailAddress: '',//详细地址
    bxClaimsInsuranceGoby: '',//出险经过

    bxClaimsOpenBank: '',//开户行
    bxClaimsBankNo: '',//银行账号

    bxClaimsCertificateImgs: '',//投保证件(投保时使用的证件)
    bxClaimsCaseHistoryImgs: '',//门(急)诊病历/住院病历/出院小结
    bxClaimsBankImgs: '',
    bxClaimsCostProveImgs: '',//医疗费用发票/费用清单/社保结算单
    bxClaimsPathologicalDiagnosisReportImgs: '',//病理诊断报告
    bxClaimsPrescriptionDrugImgs: '',//药品处方
    bxClaimsGeneticTestingReportImgs: '',//免疫组化/基因检测报告
  }
  var uploadFiles = {
    bxClaimsCertificateImgs: [],
    bxClaimsCaseHistoryImgs: [],
    bxClaimsBankImgs: [],
    bxClaimsCostProveImgs: [],
    bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
    bxClaimsPrescriptionDrugImgs: [],//药品处方
    bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
  }
  this.$store.commit('setdata', DetailList1)
  this.$store.commit('setFiles', uploadFiles)
}
Vue.prototype.$toCopy = toCopy
new Vue({
  router,
  store,
  Axios,
  render: (h) => h(App),
}).$mount('#app')
