/* eslint-disable import/extensions */
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/no-cycle
import store from '@/store';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import baseURL from '@/utils/config';
import { getToken } from '@/utils/auth';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import router from '../router/index.js'

const that = this;
// create an axios instance
const service = axios.create({
    baseURL, // url = base url + request url
    timeout: 300000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    // eslint-disable-next-line padded-blocks
    (config) => {

        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
            config.headers.token = localStorage.getItem('token');
        }
        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (res.code == 401 ||res.code == 403 ) {
              Toast.clear()
              Dialog.confirm({
                title: '系统提示',
                message: '请重新登录!',
                confirmButtonText:'确定',
                confirmButtonColor:"#5d6d8e",
                showCancelButton:false,
                className:'tishi'
              }).then(() => {
                router.push({
                  name: 'Login'
                });

              });

        }
        else if(res.code==500){
          Toast.clear()
          Dialog.alert({
              confirmButtonText: '确定',
              confirmButtonColor: "#5d6d8e",
              showCancelButton: false,
              className: 'tishi',
              title: '系统提示',
              message: '当前访问人数过多，请稍后再试!',
          })

        }
         else {
            // eslint-disable-next-line semi
            return res;

        }


        // eslint-disable-next-line padded-blocks
    },
    (error) => {
        Toast.clear()

        Dialog.alert({
            confirmButtonText: '确定',
            confirmButtonColor: "#5d6d8e",
            showCancelButton: false,
            className: 'tishi',
            title: '系统提示',
            message: '请求服务器失败，请联系客服人员!',
        })

        return Promise.reject(error);
    },
);




export const get = (url, params) => service.get(url, { params });

export const post = (url, data) => service.post(url, data);

export const put = (url, data) => service.put(url, data);

export const del = (url, data) => service.delete(url, data);

export const post1 =(url,data)=>service({
  method:'post',
  url:url,
  params:data,
})
// josn格式
export const post2 =(url,data)=>service({
  method:'post',
  url:url,
  data,
  headers: {
    "Content-Type": "application/json",
}
})
export default service;
