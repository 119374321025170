// 保险商城接口
import { get, post, post1 } from '../utils/request';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async insuranceMall({ commit }, insList) {
      const res = await post('shop/type/parentList', insList);
      return res;
    },
    async insuranceMallChilder({ commit }, childer) {
      const res = await post1('shop/type/childList', childer);
      return res;
    },
    async insuranceshopList({ commit }, shopList) {
      const res = await post1('mapper/shop/shop/list', shopList);
      return res;
    },
    async company({ commit }, companyList) {
      const res = await get('/mapper/shop/shop/shopInfo', companyList);
      return res;

    },
    // 跳转第三方链接
    async toLink({ commit }, companyList) {
      const res = await post1('shop/api/createUrl', companyList);
      return res;

    },



  },
};
