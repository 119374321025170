import { get, post, post1, post2 } from '../utils/request'
export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async greenList ({ commit }, payload) {
      const res = await get('/yh/api/index', payload)
      return res
    },
    async greenDetail ({ commit }, payload) {
      const res = await get('/yh/api/equityCardCommodity', payload)
      return res
    },
    //
    async webpay ({ commit }, payload) {
      const res = await post2('wxPay/jsApiPay/yhOrder', JSON.stringify(payload))
      return res
    },

  },
}
