import { get, post, post1, post2 } from '../../utils/request';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    // 购物车列表
    async getShopList({ commit }, payload) {

      const shopList2 = 'cart/getCartInfo';
      const res = await get(shopList2, payload);
      return res;
    },
    // 加入购物车
    async addCart({ commit }, payload) {

      const shopList1 = '/cart/addCart';
      const res = await post1(shopList1, payload);
      return res;
    },
    // 更新购物车
    async editCart({ commit }, payload) {
      const editShop = '/cart/updateCart';
      const res = await post1(editShop, payload);
      return res;
    },
    // 删除购物车
    async delCart({ commit }, payload) {
      const delShop = '/cart/deleteCart';
      const res = await post1(delShop, payload);
      return res;
    },
    // 提交订单
    async subOrder({ commit }, payload) {
      const subOrders = '/wxPay/jsApiPay/order';
      const res = await post2(subOrders, payload);
      return res;
    },

    // 校验商品是否有不属于当前登录用户所属企业的（结算接口）
    async checkShop({ commit }, payload) {
      const subOrders = '/wxPay/jsApiPay/payAccount';
      const res = await post2(subOrders, payload);
      return res;
    },
    //立即购买
     async checkShop1({ commit }, payload) {
      const subOrders = '/wxPay/jsApiPay/payAccountShop';
      const res = await post1(subOrders, payload);
      return res;
    },
    // 清除失效宝贝
    async clearList({ commit }, payload) {
      var  shopList0='cart/clearShop'
      const res = await post1(shopList0,payload);
      return res
    },
    //checkbyId(校验接口)
    async checkbyId({ commit }, payload) {
      const subOrders = '/cart/checkByPid';
      const res = await post1(subOrders, payload);
      return res;
    },
  },
};
