/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'
import warranty from "../store/modules/warranty.js"
// eslint-disable-next-line import/no-cycle
import home from "../store/home.js" //首页接口
import green from "../store/green.js" //首页接口
import insuranceMall from '../store/insuranceMall.js' //保险商城js
import insuranceMallChilder from "../store/insuranceMall.js"
import setup from "../store/modules/setup.js"
import CrinsuranceMall from "../store/modules/corporatewel.js"
import shopList from "../store/modules/shopList.js"
import address from "../store/modules/address.js"
import claims from "../store/modules/claims.js"
import libList from "../store/modules/lib.js"
import createPersistedState from "vuex-persistedstate"
import equityCenter from "../store/modules/equityCenter"
import followUpAppointment from '../store/modules/followUpAppointment'


Vue.use(Vuex)
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    DetailList: {
      bxClaimsOrderNo: '',//保单号
      bxClaimsUserId: '',//用户ID
      bxClaimsShopId: '',//保险产品ID
      bxClaimsShopName: '',//保险产品名称
      bxClaimsCompanyId: '',//保险公司Id
      bxClaimsCompanyName: '',//保险公司名称
      bxClaimsInformantName: '',//报案人姓名
      bxClaimsInformantPhone: '',//报案人手机号
      bxClaimsInformantCode: '',//手机号验证码
      bxClaimsInsurantName: '',//被保人姓名
      bxClaimsInsurantCerType: '',//被保人证件类型
      bxClaimsInsurantCerNo: '',//被保人证件号
      bxClaimsInsurantGender: '',//被保人性别(1男 2女)

      bxClaimsDangerCause: '',//出险原因
      bxClaimsTotalPrice: '',//合计理赔金额
      bxClaimsInsuranceTime: '',//出险时间
      //出险地区
      province: '',//省
      city: '',//市
      area: '',//区
      provinceCode: '',//省代码
      cityCode: '',//市代码
      areaCode: '',//区代码
      bxClaimsDetailAddress: '',//详细地址
      bxClaimsInsuranceGoby: '',//出险经过

      bxClaimsOpenBank: '',//开户行
      bxClaimsBankNo: '',//银行账号

      bxClaimsCertificateImgs: '',//投保证件(投保时使用的证件)
      bxClaimsCaseHistoryImgs: '',//门(急)诊病历/住院病历/出院小结
      bxClaimsBankImgs: '',//银行卡
      bxClaimsCostProveImgs: '',//医疗费用发票/费用清单/社保结算单
      bxClaimsPathologicalDiagnosisReportImgs: '',//病理诊断报告
      bxClaimsPrescriptionDrugImgs: '',//药品处方
      bxClaimsGeneticTestingReportImgs: '',//免疫组化/基因检测报告
    },
    uploadFiles: {
      bxClaimsCertificateImgs: [],
      bxClaimsCaseHistoryImgs: [],
      bxClaimsBankImgs: [],
      bxClaimsCostProveImgs: [],
      bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
      bxClaimsPrescriptionDrugImgs: [],//药品处方
      bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
    },
    InsuranceMall: {
      value1: 0,
      value2: 0,
      value3: 0,
      istime: true

    },
    corporateWelfare: {
      value2: 0,
      value3: 0,
      istime: true,
    }
  },
  mutations: {
    setdata (state, data) {
      state.DetailList = data
    },
    setFile (state, data) {
      state.uploadFiles.bxClaimsCertificateImgs = data
    },
    setFile1 (state, data) {
      state.uploadFiles.bxClaimsCaseHistoryImgs = data
    },
    setFile6 (state, data) {
      state.uploadFiles.bxClaimsBankImgs = data
    },
    setFile2 (state, data) {
      state.uploadFiles.bxClaimsCostProveImgs = data
    },
    setFile3 (state, data) {
      state.uploadFiles.bxClaimsPathologicalDiagnosisReportImgs = data
    },
    setFile4 (state, data) {
      state.uploadFiles.bxClaimsPrescriptionDrugImgs = data
    },
    setFile5 (state, data) {
      state.uploadFiles.bxClaimsGeneticTestingReportImgs = data
    },
    setFiles (state, data) {
      state.uploadFiles = data
    },
    setInsuranceMall (state, data) {
      state.InsuranceMall.value1 = data.value1;;
    },
    setInsuranceMall1 (state, data) {
      state.InsuranceMall.value2 = data.value2
    },
    setInsuranceMall2 (state, data) {
      state.InsuranceMall.value3 = data.value3
    },
    setInsuranceMall3 (state, data) {
      state.InsuranceMall = data
    },
    setInsuranceMall4 (state, data) {
      state.InsuranceMall.istime = data
    },
    corporateWelfare1 (state, data) {
      state.corporateWelfare.value2 = data.value2
    },
    corporateWelfare2 (state, data) {
      state.corporateWelfare.value3 = data.value3
    },
    corporateWelfare4 (state, data) {
      state.corporateWelfare.istime = data
    },
    corporateWelfare3 (state, data) {
      state.corporateWelfare = data
    },

  },
  actions: {

  },
  modules: {
    home,
    insuranceMall,
    warranty,
    insuranceMallChilder,
    setup,
    CrinsuranceMall,
    shopList,
    address,
    claims,
    libList,
    green,
    equityCenter,
    followUpAppointment

  },
})
