import { get, post } from '../../utils/request';
export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
// 上传头像
    async uploadAvar({ commit }, payload) {
      var  warranty='system/user/infoUpdateImage'
      const res = await post(warranty,payload);
      return res
    },
    async myInfo({ commit }, payload) {
      const res = await post('system/user/my',payload);
      return res
    },
    // 退出登录
    async loginOut({ commit }, payload) {
      const res = await post('system/user/outLogin',payload);
      return res
    },
    // 修改昵称editNick
    async editNick({ commit }, payload) {
      var nickEdit='/system/user/infoUpdateNickName/'+payload.NickName
      const res = await post(nickEdit,{});
      return res
    },

  },
};
