import { get, post, post1, post2 } from '../utils/request'
export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async modifyPasswrod ({ commit }, payload) {
      const res = await post1('mapper/shop/shop/listIndex', payload)
      return res

    },
    // 轮播图
    async bannerList ({ commit }, payload) {
      const res = await post('/system/user/bannerList', {})
      return res

    },
    //不再提醒
    async notReminder ({ commit }, payload) {
      const res = await post('/system/userTwo/notReminder', {})
      return res

    },
    //身份证过期
    async checkCardOverdue ({ commit }, payload) {
      const res = await post2('/system/userTwo/checkCardOverdue', payload)
      return res

    },
    //身份证姓名
    async smrzInfo ({ commit }, payload) {
      const res = await post('/system/userTwo/smrzInfo', payload)
      return res
    },
    // 补传身份材料

    async supplementCard ({ commit }, payload) {
      const res = await post('/system/userTwo/supplementCard', payload)
      return res
    },
    //单张图片上传
    async uploadImage ({ commit }, payload) {
      const res = await post('/file/uploadImage', payload)
      return res
    },
  },
}
