/*
 * @Author: 马玲雪
 * @Date: 2023-05-23 09:53:39
 * @LastEditors: 马玲雪
 * @LastEditTime: 2023-06-20 10:32:11
 * @Description: 
 * 
 */
const con = {

	//测试  理赔URL



	// bxclaimsUrl:'http://test.jk-cloud.com.cn/query/view/bxclaims.html?token=',
	//
	//  //测试  微信公众号Appid
	//  wxAppid:'wx4b4b85c0eeb3c255',
	//
	//  // 泰安  保单查询login
	//  bxLoginUrl:'http://test.jk-cloud.com.cn/query/view/login.html',
	//
	//  // 厦门  保单查询login
	//  xiaMenLoginUrl:'http://test.jk-cloud.com.cn/xiaMenQuery/view/login.html',
	//
	//  taiAn2022BaoDanUrl:'https://console.aalib.com.cn/orderItem/downPDF/',
	//
	//  //   保单查询login
	//  anYangLoginUrl:'http://test.jk-cloud.com.cn/anYangQuery/view/login.html',
	//
	//  zaoZhuangLoginUrl:'http://test.jk-cloud.com.cn/zaoZhuangQuery/view/login.html',
	//
	//  taiAn2022LoginUrl:'http://test.jk-cloud.com.cn/taiAn2022Query/view/login.html',
	//  //全联保
	//  quanLianBaoLoginUrl:'http://test.jk-cloud.com.cn/quanLianBaoQuery/view/login.html',
	//
	//  // 家财险
	//  jiaCaiXianUrl:'http://console.aalib.com.cn/orderItem/getPrintLiu/'




	//生产  理赔URL
	bxclaimsUrl: 'https://prod.jk-cloud.com.cn/view/bxclaims.html?token=',

	//生产  微信公众号Appid
	wxAppid: 'wx7e8e9be5655b3c60',

	//生产  保单查询login
	bxLoginUrl: 'https://prod.jk-cloud.com.cn',

	xiaMenLoginUrl: 'https://bccx.aalib.com.cn',

	xiaMenBaoDanUrl: 'https://console.aalib.com.cn/orderItem/downloadHmbPolicyPdf.do',

	taiAn2022BaoDanUrl: 'https://console.aalib.com.cn/orderItem/downPDFOp/',

	//   保单查询login
	anYangLoginUrl: 'https://bccx.aalib.com.cn/anYangQuery/view/login.html',

	zaoZhuangLoginUrl: 'https://bccx.aalib.com.cn/zaoZhuangQuery/view/login.html',

	taiAn2022LoginUrl: 'https://prod.jk-cloud.com.cn',
	//全联保
	quanLianBaoLoginUrl: 'https://bccx.aalib.com.cn/quanLianBaoQuery/view/login.html',

	// 家财险
	jiaCaiXianUrl: 'https://console.aalib.com.cn/orderItem/getPrintLiu/',

	// 潮州惠康保
	chaoZhouHuiKangBaoUrl: 'https://h5-saas.aalib.com.cn/query-czhkb/view/login.html?form=iwy',
	chaoZhouHuiKangBaoTestUrl: 'http://zhongantest.aalib.com.cn/query-czhkb/view/login.html?form=iwy',
	
	chaozhouDownPdf: 'https://console.aalib.com.cn/hmbPolicyDown/',
	// 电子发票下载
	fapiaoDownPdf: 'https://console.aalib.com.cn/hmbInvoiceDown/'
}

export default con