import Clipboard from "clipboard";
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
// 复制
export function toCopy(name) {

  var clipboard = new Clipboard(name);
  clipboard.on("success", () => {
    Toast('复制成功')
    clipboard.destroy();
  });
  clipboard.on("error", () => {
    clipboard.destroy();
  });
}
