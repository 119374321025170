// 保险商城接口
import { get, post, post1 } from '../../utils/request';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // 父分类
    async CorinsuranceMall({ commit }, insList) {
      const res = await post('fl/product/getAllParentType', insList);
      return res;
    },
    // 子分类
    async CorinsuranceMallChilder({ commit }, childer) {
      const res = await post1('fl/product/getAllChildType', childer);
      return res;
    },
    // 列表
    async CorinsuranceshopList({ commit }, shopList) {
      const res = await post1('fl/product/list', shopList);
      return res;
    },
       // 列表
       async CorinsuranceshopListDetail({ commit }, shopList) {
        const res = await post1('fl/product/selectOne', shopList);
        return res;
      },
    // 企业
    async  Corcompany({ commit }, companyList) {
      const res = await post1('fl/product/getAllDw', companyList);
      return res;

    },


  },
};
