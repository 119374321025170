import {
	get,
	post,
	post1,
	post2
} from '../../utils/request';
export default {
	namespaced: true,
	state: {},
	mutations: {

	},
	actions: {
		// 我的保单列表
		async myWarranty({
			commit
		}, payload) {
			var warranty = 'system/user/myBxOrderList/' + payload.bxOrderState + '/' + payload.formerYears;
			let productCode = localStorage.getItem('productCode') || '';
			let param = {

			};
			if (productCode && productCode.length > 0) {
				param.productCode = productCode;
				warranty = warranty + '?productCode=' + productCode;
			}
			const res = await post(warranty, param);
			return res
		},
		async myWarranty1({
			commit
		}, payload) {
			var warranty1 = 'system/userTwo/myBxOrderList/' + payload.bxOrderState + '/' + payload.formerYears
			const res = await post(warranty1, {

			});
			return res
		},
		// 详情
		async warDetail({
			commit
		}, payload) {
			var warranty1 = 'system/user/myBxOrderDetail/' + payload.ids + '/' + payload.types + '/' + payload
				.orderType
			const res = await post(warranty1, {

			});
			return res
		},
		// 取消订单
		async queryorder({
			commit
		}, payload) {
			var warranty12 = 'mapper/shop/order/update/' + payload.id
			const res = await post(warranty12, {

			});
			return res
		},
		// 申请退款
		async refundOrder({
			commit
		}, payload) {
			var warrantya = 'api/wxRefund'
			const res = await post1(warrantya, {
				orderId: payload.id,
				param: payload.param

			});
			return res
		},
		//我的订单
		async myFlOrder({
			commit
		}, payload) {
			const myFlOrder = 'system/user/myFlOrder'
			const res = await post1(myFlOrder, payload);
			return res;
		},
		//订单详情
		async listVoResult({
			commit
		}, payload) {
			const listVoResult = 'fl/childOrder/listVoResult'
			const res = await get(listVoResult, payload);
			return res;
		},
		//潮州惠康保订单详情
		async czhkbOrderDetail({
			commit
		}, payload) {
			const czhkbOrderDetail = 'medical/chaozhou/getOrderBase'
			const res = await get(czhkbOrderDetail, payload);
			return res;
		},
		//潮州惠康保订单取消
		async czhkbOrderCancel({
			commit
		}, payload) {
			const czhkbOrderCancel = 'medical/chaozhou/cancelOrder'
			const res = await post(czhkbOrderCancel, payload);
			return res;
		},
		//确认收货
		async sureOrder({
			commit
		}, payload) {
			const sureOrder = 'fl/childOrder/updateFlChildOrder'
			const res = await post(sureOrder, payload);
			return res;
		},
		// 生效中的
		async selectBxOrderByCerNo({
			commit
		}, payload) {
			var warrantyList = 'mapper/shop/order/selectBxOrderByCerNo'
			const res = await post2(warrantyList, payload);
			return res
		},
		//退出登录
		async taOutLogin({
			commit
		}, payload) {
			const taOutLogin = 'system/user/taOutLogin'
			const res = await post1(taOutLogin, payload);
			return res;
		},
	},
};