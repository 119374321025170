// 保险商城接口
import { get, post, post1, post2 } from '../../utils/request'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    // 权益中心列表
    async getequityCenter ({ commit }, insList) {
      const res = await post2('yh/api/equityList', JSON.stringify(insList))
      return res
    },
    // 权益激活yh/api/activeEquity
    async activequityCenter ({ commit }, insList) {
      const res = await post2('yh/api/activeEquity', JSON.stringify(insList))
      return res
    },
    // 居家上门服务
    async usageEquity ({ commit }, payload) {
      const res = await post2('yh/api/usageEquity', payload)
      return res
    },
    // onlineInquiry
    async onlineInquiry ({ commit }, payload) {
      const res = await post2('yh/api/onlineInquiry', JSON.stringify(payload))
      return res
    },
    // 详情

    async getUsageRecordDetail ({ commit }, payload) {
      const res = await post2('yh/api/getUsageRecordDetail', payload)
      return res
    },
    // 权益使用记录查看
    async getFlowRecordList ({ commit }, payload) {
      const res = await post2('yh/api/getFlowRecordList', payload)
      return res
    },
    // 权益详情
    async getEquityDetail ({ commit }, payload) {
      const res = await post2('yh/api/getEquityDetail', payload)
      return res
    },
  },
}
