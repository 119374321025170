import { get, post, post1,post2 } from '../../utils/request';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    // 地址详情
    async addressDetail({ commit }, payload) {
      const addressDetail = `shop/address/${payload}`;
      const res = await get(addressDetail);
      return res;
    },
    // 查询收货地址列表
    async findAddress({ commit }, payload) {
      const findAddress = '/shop/address/list';
      const res = await get(findAddress, payload);
      return res;
    },
    // 修改收货地址
    async editAddress({ commit }, payload) {
      const editAddress = '/shop/address/edit';
      const res = await post2(editAddress,payload.thedata);
      return res;
    },
    // 新增收货地址
    async addAddress({ commit }, payload) {
      const addAddress = '/shop/address/add';
      const res = await post2(addAddress, payload.thedata);
      return res;
    },
    // 删除收货地址
    async delAddress({ commit }, payload) {
      const delAddress = `/shop/address/${payload}`;
      const res = await post(delAddress, {});
      return res;
    },
    // 默认地址
    async defaultAddress({ commit }, payload) {
      const defaultAddress = '/shop/address/defaultAddress';
      const res = await post(defaultAddress, {});
      return res;
    },
    //修改默认收货地址
    async editDefaultAddress({ commit }, payload) {
      const editDefaultAddress = '/shop/address/editDefaultAddress';
      const res = await post1(editDefaultAddress,payload);
      return res;
    },
  },
};
