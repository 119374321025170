import { get, post, post1,post2 } from '../../utils/request';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    //获取验证码
    async claimsCode({ commit }, payload) {
      const editDefaultAddress = '/shop/claims/sendCode/'+payload.phone1;
      const res = await post1(editDefaultAddress,{});
      return res;
    },
     //校验验证码
    async claimsVerCode({ commit }, payload) {
      const checkCode = '/shop/claims/checkCode';
      const res = await post2(checkCode,{bxClaimsInformantPhone:payload.phone2,code:payload.code});
      return res;
    },
    //理赔详情
    async applyClaims({ commit }, payload) {
      const checkCode1 = '/shop/claims/goApplyClaims/'+payload.bxId;
      const res = await post1(checkCode1,{});
      return res;
    },
    // 多图片上传
    async uploadFlies({ commit }, payload) {
      var  warranty='/shop/claims/upload'
      const res = await post(warranty,payload);
      return res
    },
    // 暂存，提交理赔申请
    async subApplyClaims({ commit }, payload) {
      var  warranty='/shop/claims/applyClaims'
      const res = await post2(warranty,payload);
      return res
    },
    //理赔列表
    async claimList({ commit }, payload) {
      var  warranty='/shop/claims/list'
      const res = await post2(warranty,payload);
      return res
    },
    //理赔详情
    async claimsDetail({ commit }, payload) {
      var  warranty='/shop/claims/getInfo/'+payload.id
      const res = await post2(warranty,{});
      return res
    },
    // 撤销理赔
    async revokeClaims({ commit }, payload) {
      var  warranty='/shop/claims/annul'
      const res = await post2(warranty,payload);
      return res
    },


  },

};
