/* eslint-disable import/no-unresolved */
// js中操作cookie的插件
import Cookies from 'js-cookie';

const TokenKey = 'token';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);

}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export const pushHistory = () => {
  let state = { title: '', url: '' }
  window.history.pushState(state, state.title, state.url)
}

